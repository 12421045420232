<template>
  <div class="manage-agency-users-add animatedBox">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm" @error="validateError">
        <div class="card p-3">
          <h3 class="page-title mb-4">Add Agency User</h3>
          <!-- Agency Details -->
          <fd-form-section title="Agency Details">
            <fd-select
              v-model="newAgencyUser.branchId"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Select Branch"
              :options="branchOptions"
              selectText="Select a branch"
              :validators="[validators.required]"
            >
            </fd-select>
            <fd-select
              v-model="newAgencyUser.roleId"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Select Role"
              :options="roleOptions"
              selectText="Select a role"
              :validators="[validators.required]"
            >
            </fd-select>
            <fd-input
              v-model="newAgencyUser.renLicense"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="REN/E/PEA License"
              name="renLicense"
              type="text"
              :validators="[validators.required]"
            >
            </fd-input>
          </fd-form-section>

          <fd-form-section title="Account Credential">
            <fd-input
              v-model="newAgencyUser.email"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Email"
              name="email"
              type="email"
              :validators="[validators.required, validators.email]"
            >
            </fd-input>
            <fd-input
              v-model="newAgencyUser.password"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Password"
              name="password"
              :type="showPassword ? 'text' : 'password'"
              :validators="[validators.required, validators.password]"
              validationTooltip
            >
              <template #append-icon>
                <i
                  class="fas cursor-pointer"
                  :class="{
                    'fa-eye': !showPassword,
                    'fa-eye-slash': showPassword
                  }"
                  @click="showPassword = !showPassword"
                ></i>
              </template>
            </fd-input>
          </fd-form-section>
          <!-- Account Info -->
          <fd-form-section title="Account Info">
            <fd-select
              v-model="newAgencyUser.gender"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Select a gender"
              :options="genderOptions"
              :validators="[validators.required]"
            >
            </fd-select>
            <fd-date-picker
              v-model="newAgencyUser.birthdate"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Birth Date"
              name="birthdate"
            >
            </fd-date-picker>
            <tel-input
              ref="tel"
              v-model="phoneInput"
              :tel.sync="newAgencyUser.phoneNo"
              class="col-12 px-1 mb-2 d-block"
              label="Contact No."
              required
            >
            </tel-input>
          </fd-form-section>
          <!-- Agent Information -->
          <fd-form-section title="Agent Information">
            <div class="col-12 px-1 mb-2">
              <label class="label">Avatar</label>
              <image-uploader
                v-model="newAgencyUser.avatar"
                :multiple="false"
                @error="
                  (error, imageName) => {
                    $reportError(error, 'Upload avatar (Add A.User)');
                  }
                "
              >
              </image-uploader>
            </div>
            <fd-input
              v-model="newAgencyUser.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Name"
              name="name"
              type="text"
              :validators="[validators.required]"
            >
            </fd-input>
            <fd-input
              v-model="newAgencyUser.actualName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Actual Name"
              name="actualName"
              type="text"
              :validators="[validators.required]"
            >
            </fd-input>
            <fd-textarea
              v-model="newAgencyUser.introduction"
              class="col-12 px-1 mb-2"
              label="Introduction"
              name="introduction"
            >
            </fd-textarea>
            <fd-textarea
              v-model="newAgencyUser.experience"
              class="col-12 px-1 mb-2"
              label="Experience"
              name="experience"
            >
            </fd-textarea>
          </fd-form-section>
          <!-- Bank Details -->
          <fd-form-section title="Bank Details">
            <fd-input
              v-model="newAgencyUser.bank"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Bank Name"
              name="bank"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="newAgencyUser.bankAccountNumber"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Bank Account No."
              name="bankAccountNumber"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="newAgencyUser.bankAccountFullName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Account Full Name"
              name="bankAccountFullName"
              type="text"
            >
            </fd-input>
          </fd-form-section>
          <!-- Address Details -->
          <fd-form-section title="Address Details">
            <fd-input
              v-model="newAgencyUser.address"
              class="col-12 sm-col-6 px-1 mb-2"
              label="Address"
              name="address"
              type="text"
              :validators="[validators.required]"
            >
            </fd-input>
            <addresses
              :country.sync="newAgencyUser.country"
              :state.sync="newAgencyUser.state"
              :city.sync="newAgencyUser.city"
              :area.sync="newAgencyUser.area"
              class="d-flex row col-12"
              @change="
                (data) => {
                  newAgencyUser.countryId = data.country.id;
                  newAgencyUser.stateId = data.state.id;
                  newAgencyUser.cityId = data.city.id;
                  newAgencyUser.areaId = data.area.id;
                }
              "
            ></addresses>
          </fd-form-section>
          <div class="text-right p-2">
            <fd-button type="submit" class="fixed round medium main">
              <i class="fas fa-plus mr-1"></i> Create
            </fd-button>
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  email,
  phone,
  password
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import genderEnum from "@/enums/gender";
import Addresses from "@/components/GlobalComponents/Address";
import TelInput from "@/modules/User/components/TelInput";

import AgencyUserModel from "@/models/agencyUser";
import useUserSubmitHandler from "@/modules/User/composables/useUserSubmitHandler";

export default {
  setup(props, context) {
    let toAgencyUserList = () => {
      context.root.$router.push({
        name: "ManageAgencyUsers"
      });
    };
    const submitHandlerOptions = {
      role: "agency user",
      successCallback: toAgencyUserList
    };
    const { handleCreateSuccess, handleSubmitError } = useUserSubmitHandler(
      context,
      submitHandlerOptions
    );

    return {
      handleCreateSuccess,
      handleSubmitError
    };
  },
  components: {
    Addresses,
    TelInput,
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      branchOptions: [],
      roleOptions: [],

      genderOptions: this.$mapJsonToArray(genderEnum, (e) => {
        return {
          id: e,
          name: e
        };
      }),

      showPassword: false,
      phoneInput: "",
      newAgencyUser: {
        agencyId: "",
        branchId: "",
        roleId: "",
        renLicense: "",

        address: "",
        countryId: "",
        stateId: "",
        cityId: "",
        areaId: "",

        country: {},
        state: {},
        city: {},
        area: {},

        name: "",
        email: "",
        password: "",
        avatar: [],
        phoneNo: {},
        actualName: "",
        birthdate: "",
        introduction: "",
        experience: "",
        gender: "",

        bankAccountNumber: "",
        bankAccountFullName: "",
        bank: ""
      },

      validators: {
        required: required,
        min: minLength,
        email: email,
        password: password,
        phone: phone
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initData();
  },
  methods: {
    async initData() {
      try {
        this.$store.commit("setIsLoading", true);
        await Promise.all([this.getBranches(), this.getRoles()]);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },

    async agencyChanged() {
      this.$store.commit("setIsLoading", true);
      // Clear branch and role selection
      this.newAgencyUser.branchId = "";
      this.newAgencyUser.roleId = "";
      // Get branch & role options
      await Promise.all([this.getBranches(), this.getRoles()]);
      this.$store.commit("setIsLoading", false);
    },
    // ============================== API Related ==============================
    async getBranches() {
      try {
        let data = await this.$store.dispatch("manageBranch/getAllBranches", {
          "agency:id": this.newAgencyUser.agencyId,
          perPage: 200
        });
        this.branchOptions = this._.cloneDeep(data.data);
      } catch (error) {
        throw error;
      }
    },
    async getRoles() {
      try {
        let data = await this.$store.dispatch(
          "manageAgencyUserRole/getAllAgencyUserRoles",
          { "agency:id": this.newAgencyUser.agencyId }
        );
        this.roleOptions = this._.cloneDeep(data.data);
      } catch (error) {
        throw error;
      }
    },
    validateError() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Error",
        text: "Validation failed, please check if all the inputs are valid."
      });
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        let res = await this.$store.dispatch(
          "manageAgencyUsers/createAgencyUser",
          AgencyUserModel.postPayload(this.newAgencyUser)
        );
        this.$store.commit("setIsLoading", false);
        this.handleCreateSuccess(res);

        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.handleSubmitError(error);
        this.$reportError(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-agency-users-add {
  form {
    @extend %formDesign;
  }
  .page-title {
    font-size: 24px;
  }
}
</style>
